import React, {useState, useEffect, useContext, useCallback} from 'react';
import {Container,Form, Row, Col, Table, Nav, Navbar, NavDropdown} from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import api from '../../config/axios';
import { AuthenticateContext } from '../../config/AuthContext';
import Menu from './Menu';
import {jours, creneaux, salles} from './../../config/JourCreneauSalles';
import { Button, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa9, faDeleteLeft, faFileExcel, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import { sakkal } from '../../config/arabicfontspdf';
import jsPDF from "jspdf";
import AutoTable from 'jspdf-autotable';
import logo from '../../images/logo.png';

function ShowPlanning() {
  
    const [departements, setDepartements] = useState([]);
    const [profs,setProfs] = useState([]);
    const [planning,setPlanning] = useState([]);
    const [year,setYear] = useState(0);
    const [session,setSession] = useState(null);
    const [idProf,setIdProf] = useState(null);
    // State to manage the form data
  

    const [data, setData] = useState([
      {"jour":"Lundi","time1":"","time2":"","time3":"","time4":"","time5":""},
      {"jour":"Mardi","time1":"","time2":"","time3":"","time4":"","time5":""},
      {"jour":"Mercredi","time1":"","time2":"","time3":"","time4":"","time5":""},
      {"jour":"Jeudi","time1":"","time2":"","time3":"","time4":"","time5":""},
      {"jour":"Vendredi","time1":"","time2":"","time3":"","time4":"","time5":""},
      {"jour":"Samedi","time1":"","time2":"","time3":"","time4":"","time5":""},
    ]);
    const columns = ['', '8h30--10h30', '10h30--12h30', '12h30--14h30', '14h30--16h30', '16h30--18h30'];
    //-------dictionnaire------
    const dic = {"Lundi":0,"Mardi":1,"Mercredi":2,"Jeudi":3,"Vendredi":4,"Samedi":5};
    const time = {"8h30--10h30":"time1","10h30--12h30":"time2","12h30--14h30":"time3","14h30--16h30":"time4","16h30--18h30":"time5",}
    const [role, setRole] = useState("");

    //---------------------get Anne univ ----------------------
const getYearUniv =useCallback(async () =>{
    const url = "/api/anneeuniv";
    api.get(url)
    .then(rep =>{
        console.log("Année Universitaire: ",rep.data.annee[0].annee_univ);
        setYear(rep.data.annee[0].annee_univ);
        //getAllPlanningsByYear(rep.data.annee[0].annee_univ)
    }).catch(err => {
      console.error(err);
    });
  },[]);

    //---------------------all departement ----------------------
const getAllDepart =useCallback(async () =>{
    const url = "/api/alldepart";
    api.get(url)
    .then(rep =>{
        console.log(rep.data.departements);
        setDepartements(rep.data.departements);
    }).catch(err => {
      console.error(err);
    });
  },[]);
  //-----------------------Prof of departements--------------------
  const getProfOfDepartement = useCallback(async (id_departement) =>{
    const url = "/api/profsofdepartement/"+id_departement;
    api.get(url)
    .then(rep =>{
        console.log(rep.data.profs);
        setProfs(rep.data.profs);
    }).catch(err => {
      console.error(err);
    });
  },[]);
  
  //-----------------------planning prof--------------------
  const getPlanningOfProf =  (id_prof) =>{
    console.log(id_prof);
    const url = "/api/planning_prof/"+id_prof+"/"+year+"/"+(year+1)+"/"+session;
    console.log(url);
    api.get(url)
    .then(rep =>{
        console.log(rep.data.planningProf);
        setPlanning(rep.data.planningProf);
    }).catch(err => {
      console.error(err);
    });
  };
  //-----------------------afficher planning --------------------
  const afficherPlanningHtml = () => {
    //getPlanningOfProf(idProf); 
    setRole("");
   let plan = [
      {"jour":"Lundi","time1":"","time2":"","time3":"","time4":"","time5":""},
      {"jour":"Mardi","time1":"","time2":"","time3":"","time4":"","time5":""},
      {"jour":"Mercredi","time1":"","time2":"","time3":"","time4":"","time5":""},
      {"jour":"Jeudi","time1":"","time2":"","time3":"","time4":"","time5":""},
      {"jour":"Vendredi","time1":"","time2":"","time3":"","time4":"","time5":""},
      {"jour":"Samedi","time1":"","time2":"","time3":"","time4":"","time5":""},
    ];
    //----------crete planning------
    if (planning.length>0){
    for (let i = 0; i <planning.length; i++) {
      console.log(planning[i]["jour"]);
      let note = (planning[i]["note"].length>0) ? "("+planning[i]["note"]+")" : "";
      let idxJour = dic[planning[i]["jour"]];
      let idxTime = time[planning[i]["creneau"]];
      plan[idxJour][idxTime]= planning[i]["module_name"] + "<br/> SM"+planning[i]["id_semestre"] + "<br/>" + planning[i]["salle"]
      + "<br/>" + planning[i]["libelle_filiere"]+"<br/>"+ note;
    }
    //--------------------role------------------------
    if (planning[0].role == 2){
      setRole("* Chef de département")
   }else if (planning[0].role == 3){
    setRole("* Vice Doyen")
   }else if (planning[0].role == 4){
    setRole("* Doyen");
   }
    //-------------------------------------
    setData(plan);
  }//if planning
  }
  //--------------------------PDF-----------------------
  //---------------------------
  const generatePdfPlanning = () => {
    setData([
          {"jour":"Lundi","time1":"","time2":"","time3":"","time4":"","time5":""},
          {"jour":"Mardi","time1":"","time2":"","time3":"","time4":"","time5":""},
          {"jour":"Mercredi","time1":"","time2":"","time3":"","time4":"","time5":""},
          {"jour":"Jeudi","time1":"","time2":"","time3":"","time4":"","time5":""},
          {"jour":"Vendredi","time1":"","time2":"","time3":"","time4":"","time5":""},
          {"jour":"Samedi","time1":"","time2":"","time3":"","time4":"","time5":""},
        ]);
  if (planning.length>0){
    //----------crete planning------
    for (let i = 0; i <planning.length; i++) {
      console.log(planning[i]["jour"]);
      let note = (planning[i]["note"].length>0) ? "("+planning[i]["note"]+")" : "";
      let idxJour = dic[planning[i]["jour"]];
      let idxTime = time[planning[i]["creneau"]];
      data[idxJour][idxTime]= planning[i]["module_name"] + "\n SM"+planning[i]["id_semestre"] + "\n" + planning[i]["salle"]
      + "\n" + planning[i]["libelle_filiere"]+"\n"+ note;
    }

    //------------------------------
    
    const doc = new jsPDF({
        orientation: 'landscape', // Set the orientation to landscape
      });
    doc.addFileToVFS('majalla-normal.ttf', sakkal);
    doc.addFont('majalla-normal.ttf', 'majalla', 'normal');
    doc.setFont("majalla");
    
        var img = new Image();
        img.src = logo;
        doc.addImage(img, 'png',130, 5, 0, 15);
        let s = (planning[0].id_sessions==1)? "   |  Session d'automne":"   |  Session de printemps";
        let titre = "Année Universitaire :"+year+"/"+(year+1)+"  |  Professeur: "+planning[0].nom_fr+" "+planning[0].prenom_fr
        +"   |  grade: "+planning[0].grade+ s;

    doc.text(titre, 20,30);
    // Role en bas de page
    if (planning[0].role == 2){
      setRole("* Chef de département")
   }else if (planning[0].role ==3){
    setRole("* Vice Doyen")
   }else if (planning[0].role ==4){
    setRole("* Doyen");
   }
      
      //var textWidth = doc.getStringUnitWidth(nb) * doc.internal.getFontSize();
      //var pageWidth = doc.internal.pageSize.getWidth();
      var textX =30;// (pageWidth - textWidth) / 2;
      var textY = doc.internal.pageSize.getHeight() - 10; // 10 is the margin from the bottom

      // Add the text to the document
      doc.text(textX, textY, role);
    
  
    
    
    
    doc.autoTable({
        startY: 40,
        theme:'grid',
        headStyles: { fillColor: '#00aced', textColor: '#000000' , fontStyle: 'arial' },
        bodyStyles: {textColor: '#333333' , font: 'majalla' ,fontSize: 12},
        styles: {
            halign: 'center', // Horizontal alignment
            valign: 'middle', // Vertical alignment
             fontSize: 12,
            cellPadding: 4,
            //fillColor: '#f2f2f2', // Table header color
          },
        head:[columns],
        columns: [
            { header: '', dataKey: 'jour' },
            { header: '', dataKey: 'time1' },
            { header: '', dataKey: 'time2' },
            { header: '', dataKey: 'time3' },
            { header: '', dataKey: 'time4' },
            { header: '', dataKey: 'time5' },
            
          ],
        body: data,
      });
      let prof= profs.filter(p=>p.id_professor == idProf);
      let MyFilename = prof[0].nom_fr+"_"+prof[0].prenom_fr+".pdf";
      doc.save(MyFilename);

    }//if planning.length>0
  };

  //------------------------------------------------
  useEffect(()=>{
    getYearUniv();
    getAllDepart();
  },[getYearUniv,getAllDepart,data,idProf]);

  //------------------------------------------------
  return (
    <Container>
      <Menu/>
      <h2 className='mt-4 text-center text-bg-info'>Emploi du temps: Enseignants</h2>
      <Form >
       {/*====================================AU==================================== */}
       <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Année Universitaire</Form.Label>
            <Form.Control className='inputPlanning' disabled type="text"  value={year+"/"+(year+1)}  />
        </Form.Group>
        <Row>
    {/*====================================Département==================================== */}
    <Form.Group className="mb-3 col-4" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Département</Form.Label>
            <Form.Select name='idprof' className='inputPlanning' onChange={(e) =>{getProfOfDepartement(e.target.value); setSession("session"); setIdProf("Professeur");}} >
                  <option value=''></option>
                  {departements.map(dep =><option key={dep.id_departement} value={dep.id_departement}>{dep.libelle_departement}</option>)}
                  
              </Form.Select>
        </Form.Group>
    {/*====================================session==================================== */}
    <Form.Group className="mb-3 col-4" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Session</Form.Label>
            <Form.Select value={session} name='session' className='inputPlanning' onChange={(e) =>{setSession(e.target.value);setIdProf("Professeur");}} >
                  <option value=''>Session</option>
                  <option value='1'>Automne</option>
                  <option value='2'>Printemps</option>
            </Form.Select>
        </Form.Group>
     {/*====================================Profs==================================== */}
     <Form.Group className="mb-3 col-4" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Professeur</Form.Label>
            <Form.Select value={idProf} name='idprof' className='inputPlanning' onChange={(e) =>{getPlanningOfProf(e.target.value); setIdProf(e.target.value)}}>
            <option selected value=''>Professeur</option>
            {profs?.map((p,i) => <option value={p.id_professor} key={i}>{p.nom_fr} {p.prenom_fr}</option>)}
             </Form.Select>
        </Form.Group>

           
</Row>
  
{/*====================================Button==================================== */}
<Row>
<Form.Group className="mb-3 col-4 offset-1">
  <Button className="col-12" variant="contained" color="success" onClick={afficherPlanningHtml}>Afficher</Button>
</Form.Group>
<Form.Group className="mb-3 col-4 offset-1">
  <Button className="col-12" variant="contained" color="error" onClick={generatePdfPlanning}><FontAwesomeIcon size='2x' icon={faFilePdf} /></Button>
</Form.Group>


  </Row>
  </Form>
  
  
  <Row>
    <Table striped bordered className='tab'>
   <thead >
    <tr className='text-center table-primary'>
      {columns.map((c,i)=><th key={i}>{c}</th>)}
    </tr>
  </thead>
      <tbody>
        {data.map((d,i)=><tr className='text-center' key={i}>
          <td>{d.jour}</td>
          <td dangerouslySetInnerHTML={{__html:d.time1}}></td>
          <td dangerouslySetInnerHTML={{__html:d.time2}}></td>
          <td dangerouslySetInnerHTML={{__html:d.time3}}></td>
          <td dangerouslySetInnerHTML={{__html:d.time4}}></td>
          <td dangerouslySetInnerHTML={{__html:d.time5}}></td>
        </tr>)}
      </tbody>
    </Table>
    <h4>{role}</h4>
  </Row>
    </Container>
  )
}

export default ShowPlanning