import React, { useContext, useState } from 'react'
import {Button,Form,Row, Col} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import api from '../config/axios';
import { AuthenticateContext } from '../config/AuthContext';
import { useNavigate } from 'react-router-dom';

function  Login()  {
    const [authInfo,setAuthInfo] = useContext(AuthenticateContext);
    const [msgError,setMsgError] = useState('');
    const { register, handleSubmit} = useForm();
    const navigate = useNavigate();
const sendlogin = async (data) => {
    api.post('/api/login',{
        "email":data.email,
        "password":data.pwd
    }).then(rep => {
      setMsgError('');
      setAuthInfo(()=>({token:rep.data.jwt,isAuthenticated:true}));
      
      console.log(rep.data.user)
      localStorage.setItem('nom',rep.data.user[0].nom+" "+rep.data.user[0].prenom);
      localStorage.setItem('role',rep.data.user[0].role);
      if (rep.data.user[0].role === "chef-département"){
        localStorage.setItem('departement',rep.data.user[0].id_departement);
        navigate('/cpdepartement');
      }
      if(rep.data.user[0].role === "admin" || rep.data.user[0].role === "super-admin"){
        navigate('/cpadmin');
      }
      
    }).catch(err => {
        setMsgError("Error Login or password!");
        console.error(err);
    }).finally(() => {
        console.log('Thanks');
    });
  }
  return (
   <>
   <Row>
      <Col style={{'color':'#ad5389'}} className="display-1 text-uppercase text-center">
           FLSHM-Pédagogie
      </Col>
    </Row>
    <Row>
   <Form onSubmit={handleSubmit(sendlogin)}>
      <Form.Group className="mb-3" controlId="formBasictext">
        <Form.Label>Login</Form.Label>
        <Form.Control {...register('email')} type="text" placeholder="email" />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasictext2">
        <Form.Label>Mot de passe</Form.Label>
        <Form.Control {...register('pwd')} type="password" placeholder="password" />
      </Form.Group>    
      <Button variant="primary" type="submit">
        Entrer
      </Button>
    </Form>
    <Col>
    <div className='text-danger'>{msgError}</div>
    </Col>
    </Row>
   </>
  )
}

export default Login