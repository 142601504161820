import React, { useState, useCallback, useEffect } from 'react'
import {Table} from 'react-bootstrap';
import api from '../../config/axios';
import Menu from './Menu';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import {jours, creneaux} from './../../config/JourCreneauSalles';
import { TableBody, TableHead } from '@mui/material';
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

function SallesDispo() {
    const [year,setYear] = useState(0);
    const [salles,setSalles] = useState([]);
    //-----------------jour------------------
    const [jour, setjour] = React.useState('');
    const handleChangeJour = (event) => {
        setjour(event.target.value);
        console.log(event.target.value)
        console.log(jour)
      };
      //-----------------jour------------------
    const [creneau, setCreneau] = useState('');
    const handleChangeCreneau = (event) => {
        setCreneau(event.target.value);
        console.log(event.target.value)
        console.log(creneau)
      };
    //-------------------------------------
    //------------getYear-----------
    //---------------------get Anne univ ----------------------
const getYearUniv =useCallback(async () =>{
    console.log("im getYearUniv im executing.....");
    const url = "/api/anneeuniv";
    api.get(url)
    .then(rep =>{
       // console.log(rep.data.annee[0].annee_univ);
        setYear(rep.data.annee[0].annee_univ);
        
    }).catch(err => {
      console.error(err);
    });
  },[]);

    //-----------------------------
    const getSallesDisp =async () =>{
        let year1 = year;
        let year2 = year1+1;
        //let jour = 'Jeudi';
        //let creneau = '16h30--18h30';
        api.get('/api/sallesdispo/'+year1+'/'+year2+'/'+jour+'/'+creneau)
        .then(rep =>{
            
            console.log(rep.data.salles_disponibles);
            setSalles(rep.data.salles_disponibles)
            

        }).catch(err => {
    console.error(err);
  });
    }
    //---------------------------
    useEffect(()=>{
        getYearUniv();
       
      },[getYearUniv]);
  return (
    <Box sx={{ flexGrow: 1 }}>
        <Menu/>
    <Grid container spacing={2} marginTop={4}>
    <Grid item xs={12}>
        <div className='display-3 text-primary text-center'>Les Salles disponibles</div>
      </Grid>
      {/**========================================================================= */}
      <Grid item xs={6}>
       
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label">jour</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={jour}
          label="jour"
          onChange={e=>handleChangeJour(e)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {jours.map((j,i) =><MenuItem value={j} key={i}>{j}</MenuItem>)}
        </Select>
       
      </FormControl>
        
      </Grid>
      {/**===============================Créneau================================================ */}
      <Grid item xs={6}>
        <FormControl className='col-12' sx={{ m: 1, minWidth: 120 }}>
        <InputLabel id="demo-simple-select-helper-label2">Créneau</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label2"
          value={creneau}
          label="Créneau"
          onChange={e=>handleChangeCreneau(e)}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {creneaux.map((j,i) =><MenuItem value={j} key={i}>{j}</MenuItem>)}
        </Select>
       
      </FormControl>
      </Grid>
      {/**=============================================================================== */}      
    </Grid>

    <Grid container spacing={2}>
    <Grid item xs={3}></Grid>
      <Grid item xs={6} marginTop={2}> 
          <Button onClick={getSallesDisp} className='col-12'  variant="contained" color="success">
                  Afficher
          </Button>
      </Grid>
      <Grid item xs={3}></Grid>
    </Grid>
    {/**--------------------------------------------- */}
    <Grid container spacing={2}>
    <Grid item xs={1} md={3}></Grid>
    <Grid item xs={10} md={6} marginTop={2}> 
        <Table striped>
            <TableHead>
                <tr><th>{salles.length} salles disponibles le {jour} à {creneau}</th></tr>
            </TableHead>
            <TableBody>
                {salles.map((obj,i) =><tr key={i}><td>{obj.name_fr}</td></tr>)}
            </TableBody>
        </Table>
      </Grid>
      <Grid item xs={1} md={3}></Grid>
    </Grid>

    {/**--------------------------------------------- */}
  </Box>
  )
}

export default SallesDispo