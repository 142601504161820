import React from 'react'
import {Container, Nav, Navbar, NavDropdown} from 'react-bootstrap';
import { useContext } from 'react';
import { AuthenticateContext } from '../../config/AuthContext';
import '../departement/Menu'
import Menu from './Menu';


function CpAdmin() {
    const [,setAuthInfo] = useContext(AuthenticateContext);
    const nom = localStorage.getItem('nom');
    const role = localStorage.getItem('role');
  return (
    <Container> 
    <Menu/>
     

      <h1>Espace Admin</h1>
    </Container>
  )
}

export default CpAdmin