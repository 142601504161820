
import React, {useState, useEffect, useContext, useCallback} from 'react';
import {Container,Form, Row, Col, Table,Modal} from 'react-bootstrap'
import { useForm } from 'react-hook-form';
import api from '../../config/axios';
import { AuthenticateContext } from '../../config/AuthContext';
import Menu from './Menu';
import {jours, creneaux} from './../../config/JourCreneauSalles';
import { Button, Stack } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { fa9, faDeleteLeft, faEdit, faFileExcel, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import * as XLSX from 'xlsx';

function AddPlanning() {
    const [authInfo,setAuthInfo] = useContext(AuthenticateContext);
    const { register,reset, handleSubmit} = useForm()
    const nom = localStorage.getItem('nom');
    const role = localStorage.getItem('role');
    const [departements, setDepartements] = useState([]);
    const [filieres,setFilieres] = useState([]);
    const [profs,setProfs] = useState([]);
    const [semestre,setSemestre] = useState([]);
    const [modules,setModules] = useState([]);
    const [modulesSemestre,setModulesSemestre] = useState([]);
    const [year,setYear] = useState(0);
    const [plannings,setPlannings] = useState([]);
    const [filtredPlannings,setFiltredPlannings] = useState([]);
    const [salles,setSalles] = useState([]);
    //-------------------------------------------
    const [rechNom,setRechNom] = useState("");
    const [rechMod,setRechMod] = useState("");
    const [rechFil,setRechFil] = useState("");
    const [rechDep,setRechDep] = useState("");
    const [rechSession,setRechSession] = useState("");
    const [rechSem,setRechSem] = useState("");
    const [rechJour,setRechJour] = useState("");
    const [rechHeure,setRechHeure] = useState("");
    const [rechSalle,setRechSalle] = useState("");
    const [rechHebdo,setRechHebdo] = useState(-1);
    const [nbrCours,setNbrCours] = useState(0);
    const [indicateur, setIndicateur] = useState(false);
    const [uniqueFilieres,setUniqueFilieres] = useState([]);
    const [uniqueDepartements,setUniqueDepartements] = useState([]);
     //-----------------Modal-------------------
     const [show, setShow] = useState(false);
     const [del, setDel] = useState(0);
     const [showEdit, setShowEdit] = useState(false);
     const [editId, setEditId] = useState(0);
     const [modifPlan, setModifPlan] = useState({
      "id":0,
      "nom_fr": "",
    "prenom_fr": "",
    "module_name": "",
    "libelle_filiere": "",
    "libelle_departement": "",
    "session": "",
    "semestre": "",
    "jour": "",
    "creneau": "",
    "salle": "",
    "groupe": "",
    "hebdo": "",
    "note": "",
    "id_module":0,
    "annee_univ":"",
    "id_professor":0


     });
     const handleClose = () => setShow(false);
     const handleShow = (delId) => {setDel(delId); setShow(true); }
     const handleCloseEdit = () => {setShowEdit(false); reset();}
     const handleShow2 = (editId) => {setEditId(editId); setShowEdit(true); reset(); }
     //--------------------modal2 added---------------
     const [show2, setShow2] = useState(false);
     const handleClose2 = () => setShow2(false);
     const [msg, setMsg] = useState("");
     const [classe, setClasse] = useState("text-info h4");
//---------------------all departement ----------------------
const getAllDepart =useCallback(async () =>{
  const url = "/api/alldepart";
  api.get(url)
  .then(rep =>{
      //console.log(rep.data.departements);
      setDepartements(rep.data.departements);
  }).catch(err => {
    console.error(err);
  });
},[]);
//---------------------all salle ----------------------
const getAllSalles =useCallback(async () =>{
  console.log("im getAllSalles im executing.....");
  const url = "/api/salles";
  api.get(url)
  .then(rep =>{
     // console.log(rep.data.salles);
      setSalles(rep.data.salles);
  }).catch(err => {
    console.error(err);
  });
},[]);
//===========================getAllPlanningsByYear=====================================
const getAllPlanningsByYear = useCallback(async (annee) => {
  console.log("im getAllPlanningsByYear im executing.....");
  let A1 = annee;
  let A2 = A1 + 1;
  const url = "api/allplannins/"+A1+"/"+A2;
    api.get(url)
    .then(rep =>{
        let lst = rep.data.plannings;
        setPlannings(lst);
        //-----------liste d'uniques filières & départements
        let lstdep = lst.map(d => d.libelle_departement);
        let lstfil = lst.map(d => d.libelle_filiere);
        const uniqueDep = lstdep.filter((item, index, arr) => arr.indexOf(item) === index);
        const uniqueFil = lstfil.filter((item, index, arr) => arr.indexOf(item) === index);
        setUniqueDepartements(uniqueDep);
        setUniqueFilieres(uniqueFil);
        //------------------------------------------------
        setIndicateur(true);
       // console.log(rep.data.plannings);
    })
    .catch(err => {
       console.error(err);
    });    
},[]);
//---------------------get Anne univ ----------------------
const getYearUniv =useCallback(async () =>{
  console.log("im getYearUniv im executing.....");
  const url = "/api/anneeuniv";
  api.get(url)
  .then(rep =>{
     // console.log(rep.data.annee[0].annee_univ);
      setYear(rep.data.annee[0].annee_univ);
      getAllPlanningsByYear(rep.data.annee[0].annee_univ);
      
  }).catch(err => {
    console.error(err);
  });
},[getAllPlanningsByYear]);
//===========================getFilieresAndPorfOfDepartement=====================================
const getFilieresAndPorfOfDepartement = useCallback(async (id_departement) => {
  const url = "/api/filieresofdepart/"+id_departement;
    api.get(url)
    .then(rep =>{
        setFilieres(rep.data.filieres);
       // console.log(rep.data.filieres);
    }).catch(err => {
       console.error(err);
    });    
},[]);

//================================================================
//---------------getModules du filière----------
const getModules = async (idfil) => {
  const url = "/api/modulesoffiliere/"+idfil;
  api.get(url)
  .then(rep =>{
      setModules(rep.data.modules);
     // console.log(rep.data.modules);
     // console.log(idfil);

      
      setSemestre([]);
      //-----------------------extraction des semestre uniques--
     let sem = [];
      let count = 0;
      let start = false;
       
      for (let j = 0; j < rep.data.modules.length; j++) {
          for (let k = 0; k < sem.length; k++) {
              if (rep.data.modules[j].id_semestre === sem[k]) {
                  start = true;
              }
          }
          count++;
          if (count === 1 && start === false) {
              sem.push(rep.data.modules[j].id_semestre);
          }
          start = false;
          count = 0;
      }
      setSemestre(sem);

      ////---------------fin extraction des semestre uniques-------------------------
    
      //console.log(semestre);
  }).catch(err => {
  console.error(err);
  });
  }
//=====================Modules d'une semester=======================
const modulesOfSemester = (sem) => {
  let arr = [];
  setModulesSemestre(arr);
  for(let i = 0; i < modules.length; i++){
    if(modules[i].id_semestre == sem){
      arr.push(modules[i]);
    }
  }
  setModulesSemestre(arr);
}
//=====================Prof&Groupe==================================
let A1 = year;
let A2 = A1 + 1;
const getProfAndGroupebyYearAndModule = async (id_module) => {
  setProfs([]);
  let url = "/api/profbymodule/"+A1+"/"+A2+"/"+id_module;
  api.get(url)
  .then(rep => {
    //console.log(rep.data.prof);
    setProfs(rep.data.prof);
    //--------------------------------------------------
    let tabIdProf = plannings.map(p => p.id_professor);
   // console.log('--------tab---', tabIdProf);
    let arr = (rep.data.prof).filter(prof =>true);
   /* let unused = arr.filter(prof => !tabIdProf.includes(prof.id_professor));
    console.log("--------every plannings : ", plannings.length);
    console.log("--------every profs : ", arr.length);
    console.log("--------every : ", arr);
    console.log('====unused ===', unused);
    setProfs(unused);*/
    //-----------------------------------------------
  }).catch(err => {
    console.error(err);
  });
}
//-----------------------filter---------------------------
const filterPlanning = useCallback(()=>{
  //if (indicateur){
    console.log("im filter im executing.....");
    let lst = plannings?.filter(p => {
      return rechNom === '' ? p : p.nom_fr.toLowerCase().includes(rechNom);
    }).filter(p => {
      return rechMod === '' ? p : p.module_name.toLowerCase().includes(rechMod);
    }).filter(p => {
      return rechFil === '' ? p : p.libelle_filiere.toLowerCase().includes(rechFil);
    }).filter(p => {
      return rechDep === '' ? p : p.libelle_departement.toLowerCase().includes(rechDep);
    }).filter(p => {
      return rechSession === '' ? p : p.id_sessions==rechSession;
    }).filter(p => {
      return rechSem === '' ? p : p.id_semestre==rechSem;
    }).filter(p => {
      return rechJour === '' ? p : p.jour.toLowerCase().includes(rechJour);
    }).filter(p => {
      return rechHeure === '' ? p : p.creneau.toLowerCase().includes(rechHeure);
    }).filter(p => {
      return rechSalle === '' ? p : p.salle.includes(rechSalle);
    }).filter(p => {
      return rechHebdo ==-1 ? p : p.hebdo == rechHebdo;
    });
    setFiltredPlannings(lst);
    setNbrCours(lst.length);
    setIndicateur(false);
 // } //if
  
  },[rechJour,rechHeure,rechMod,rechNom,rechDep,rechSem,rechSession,rechFil,rechSalle,rechHebdo,nbrCours,indicateur]);

//----------------------------------------------------
//-----------------------------------------------------------
  const sendPlanning = (data) => {
    data.hebdo = !data.hebdo;
    //console.log(data);
    api.post('/api/planning',{
      "id_professor":data.id_professor,
      "id_module":data.id_module,
      "groupe":data.groupe,
      "jour":data.jour,
      "creneau":data.creneau,
      "salle":data.salle,
      "annee_univ":year+"/"+(year+1),
      "hebdo":data.hebdo,
      "note":data.note,
    },
    {
      headers: {
        'auth-token': authInfo.token
        }
    }).then(rep => {
      getYearUniv();// dans cette fonction il y a aussi la fonction de récupération des plannings
      console.log("*********************************");
      console.log(rep.data);
      if(rep.data.success){
        setMsg("<b>Planning bien ajouté</b>");
        setClasse("text-info");
        
      }else{
        
            if(rep.data.planning1.length > 0){
              setMsg("Impossible d'ajouter ce planning!<br/> Créneau attribué à "+rep.data.planning1[0].prenom_fr+" "+rep.data.planning1[0].nom_fr);
            }
            if(rep.data.planning2.length > 0){
              setMsg("Impossible d'ajouter ce planning!<br/>  Cet enseignat(e) n'est pas disponible dans cet créneau, il (elle) assure le cours "+rep.data.planning2[0].module_name+" dans la salle: "+rep.data.planning2[0].salle);
            }
            if(rep.data.is_chevauchement){
              let actualSem = rep.data.actualSem;
              let chev = rep.data.overlapedWith[0];
              
              setMsg("Impossible d'ajouter ce planning!<br/>  Chevauchement entre les semestres: <br> module de sem:"+actualSem+"<br> chevauché avec sem:"+chev);
            }
          
            setClasse("text-danger");
      }
      setShow2(true);
      reset();
    });
  }
//==========================Update Planning ======================
const sendUpdatePlanning = (data) => {
  data.hebdo = !data.hebdo;
  console.log("====Update ====");
  console.log(data);
  handleCloseEdit();
  //console.log(data);
  api.post('/api/updateplanning',{
    "idUpdate":data.idUpdate,
    "jour":data.jour,
    "creneau":data.creneau,
    "salle":data.salle,
    "hebdo":data.hebdo,
    "note":data.note,
    "id_module":data.idModule,
    "annee_univ":data.annee_univ,
    "id_professor":data.idProf
  },
  {
    headers: {
      'auth-token': authInfo.token
      }
  }).then(rep => {
    getYearUniv();// dans cette fonction il y a aussi la fonction de récupération des plannings
    console.log("*********************************");
    console.log(rep.data);
    if(rep.data.success){
      setMsg("<b>Planning bien Modifié</b>");
      setClasse("text-info");
      
    }else{
      
          if(rep.data.planning1.length > 0){
            setMsg("Impossible d'ajouter ce planning!<br/> Créneau attribué à "+rep.data.planning1[0].prenom_fr+" "+rep.data.planning1[0].nom_fr);
          }
          if(rep.data.planning2.length > 0){
            setMsg("Impossible d'ajouter ce planning!<br/>  Cet enseignat(e) n'est pas disponible dans cet créneau, il (elle) assure le cours "+rep.data.planning2[0].module_name+" dans la salle: "+rep.data.planning2[0].salle);
          }
          if(rep.data.is_chevauchement){
            let actualSem = rep.data.actualSem;
            let chev = rep.data.overlapedWith[0];
            
            setMsg("Impossible d'ajouter ce planning!<br/>  Chevauchement entre les semestres: <br> module de sem:"+actualSem+"<br> chevauché avec sem:"+chev);
          }
        
          setClasse("text-danger");
    }
    setShow2(true);
    reset();
  });
}
//==========================Delete planning========================
const deletePlanning = async (id) => {
  api.delete('/api/deleteplanning/' +id,{
    headers: {
      'auth-token': authInfo.token
      }
  }).then(rep => {
    console.log(rep.data);
    getAllPlanningsByYear(year);
    
  }).catch(err => {
    console.log(err);
  });
}
  //==============================export Excel========================
  const dataForExport = useCallback(()=>{
    let lst = filtredPlannings;
    var wb = XLSX.utils.book_new(),
     ws = XLSX.utils.json_to_sheet(lst);
    XLSX.utils.book_append_sheet(wb, ws,"profs");
    XLSX.writeFile(wb, "EmploiDuTemps.xlsx");
    
   },[filtredPlannings])

  //===============================================================
  
  useEffect(()=>{
    getYearUniv();
    getAllSalles();
    getAllDepart();
   },[getAllDepart,getAllSalles,getYearUniv]);
//--------------------------------------------
  useEffect(()=>{
    filterPlanning();
   },[filterPlanning]);
   
  //===============================================================
  function onlyUnique(value, index, array) {
    return array.indexOf(value) === index;
  }
 //===============================================================
  return (
    <Container>
      <Menu/>
      <Row>
     <Col>
      <hr className='trait'/>
      <h3>Emploi du temps</h3>
      </Col>
     </Row>
     <Row>
        <Col md={12}>
        <div>
      <Form onSubmit={handleSubmit(sendPlanning)}>

        {/*====================================AU==================================== */}
        <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Année Universitaire</Form.Label>
            <Form.Control className='modif' disabled type="text"  value={year+"/"+(year+1)} {...register('annee_univ')} />
        </Form.Group>
<Row>
    {/*====================================Département==================================== */}
    <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Département</Form.Label>
            <Form.Select className='myselect' onChange={(e) =>{getFilieresAndPorfOfDepartement(e.target.value)}} >
                  <option value=''></option>
                  {departements.map(dep =><option key={dep.id_departement} value={dep.id_departement}>{dep.libelle_departement}</option>)}
                  
              </Form.Select>
        </Form.Group>
     {/*====================================Filière==================================== */}
     <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Filière</Form.Label>
            <Form.Select className='myselect' onChange={(e) =>{getModules(e.target.value)}}>
            <option value=''>filière</option>
            {filieres?.map((fil,i) => <option value={fil.id_filiere} key={i}>{fil.libelle_filiere}</option>)}
             </Form.Select>
        </Form.Group>
</Row>

<Row>
    {/*====================================Semestre==================================== */}
    <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Semestre</Form.Label>
            <Form.Select className='myselect' onChange={(e) => {modulesOfSemester(e.target.value)}} >
                  <option value=''>Semestre</option>
                  {semestre.map((s,i) =><option value={s} key={i}>{"SM"+s}</option>)}
                  
              </Form.Select>
        </Form.Group>
     {/*====================================Module==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Module</Form.Label>
            <Form.Select {...register('id_module')} className='myselect' onChange={e => getProfAndGroupebyYearAndModule(e.target.value)}>
            <option value=''>module</option>
            {modulesSemestre?.map((mod,i) =><option value={mod.id_module} key={i}>{mod.module_name}</option>)}
             </Form.Select>
        </Form.Group>
</Row>

<Row>
    
     {/*==================================== Prof==================================== */}
     <Form.Group className="mb-3 col-12" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Professeur</Form.Label>
            <Form.Select {...register('id_professor')} className="myselect" >
                  <option value=''>{profs.length==0?"Emploi réalisé ou module non affecté":""}</option>
                  {profs?.map((p,i) =><option value={p.id_professor} key={i}>{p.nom_fr} {p.prenom_fr} / {"Groupe: "+p.groupe} </option>)}
                  
              </Form.Select>
        </Form.Group>
        {/*====================================Groupe ==================================== */}
     <Form.Group className="mb-3 col-12" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Groupe</Form.Label>
            <Form.Select {...register('groupe')} className="myselect" >
                  <option value=''>{profs.length==0?"Emploi réalisé ou module non affecté":""}</option>
                  {profs?.map((p,i) =><option value={p.groupe} key={i}>{p.groupe} </option>)}
                  
              </Form.Select>
        </Form.Group>
     
</Row>
<Row>
    {/*====================================Jour==================================== */}
    <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Jour</Form.Label>
            <Form.Select {...register('jour')} className='myselect' >
                  <option value=''>Jour</option>
                  {jours.map((j,i) =><option value={j} key={i}>{j}</option>)}
                  
              </Form.Select>
        </Form.Group>
        <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Salle</Form.Label>
            <Form.Select {...register('salle')} className='myselect' >
                  <option value=''>Choisir la salle</option>
                  {salles.map((s,i) =><option value={s.name_fr} key={i}>{s.name_fr}</option>)}
                  
              </Form.Select>
        </Form.Group>
     {/*====================================Créneau==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Créneau</Form.Label>
            <Form.Select {...register('creneau')} className='myselect'>
            <option value=''>Créneau</option>
            {creneaux.map((c,i) =><option value={c} key={i}>{c}</option>)}
             </Form.Select>
        </Form.Group>
</Row>
<Row>
    {/*====================================Hébdo==================================== */}
    <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Hébdomadaire (Par défaut)</Form.Label>
            <Form.Check {...register('hebdo')} // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Irrégulier"
      />
        </Form.Group>
     {/*====================================Créneau==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Note</Form.Label>
            <Form.Control className='textareaplanning' {...register('note')} as="textarea" rows={3} />
        </Form.Group>
</Row>
{/*====================================Button affecter==================================== */}
<Row>
<Form.Group className="mb-3 col-4">
  <Button className="col-12" variant="contained" color="info" type="submit">Ajouter</Button>
</Form.Group>

  <Form.Group className=" col-4 mb-3">
  <Button onClick={()=>reset()} className='col-4' variant="contained" color="warning" type="reset">Effacer</Button>
  </Form.Group>
  </Row>
    </Form>
      </div>
      </Col>
     </Row>
     {/**=========================================================== */}
     <Row>
     <Col>
      <hr className='trait'/>
      <h3>{nbrCours} cours</h3>
      </Col>
      <Col md={12}>
      <Button variant="contained" color="success" className='col-3 mb-2' onClick={()=>dataForExport()} ><FontAwesomeIcon size='2x' icon={faFileExcel} /></Button>
      </Col>
     </Row>
     <Row>
      <Col>
      <Table striped bordered hover className='tab'>
        <thead>
          <tr>
            <th>Professeur</th>
            <th>Module</th>
            <th>Filière</th>
            <th>Département</th>
            <th>Session</th>
            <th>Semestre</th>
            <th>Jour</th>
            <th>Créneau</th>
            <th>Salle</th>
            <th>Groupe</th>
            <th>Hébdo</th>
            <th>Note</th>
            <th><FontAwesomeIcon size={fa9} icon={faDeleteLeft} /></th>
          </tr>
        </thead>
            <tbody>
            <tr>
            
            <td><input type='text' className='form-control modif' onChange={e =>{setRechNom(e.target.value.toLowerCase()); filterPlanning()}}/></td>
            <td><input type='text' className='form-control modif' onChange={e => {setRechMod(e.target.value.toLowerCase()); filterPlanning()}}/></td>
            <td>
            <select  className='form-control myselect' onChange={(e) => {setRechFil(e.target.value.toLowerCase()); filterPlanning()}}>
            <option value="" selected>All </option>
            {uniqueFilieres.map((fil, i) =><option value={fil} key={i}>{fil}</option>)}
          </select>
            </td>
            <td>
            <select  className='form-control myselect' onChange={(e) => {setRechDep(e.target.value.toLowerCase()); filterPlanning()}}>
            <option value="" selected>All </option>
            {uniqueDepartements.map((dep, i) =><option value={dep} key={i}>{dep}</option>)}
          </select>
            </td>
            <td>
            <select  value={rechSession}  className='form-control myselect' onChange={(e) => {setRechSession(e.target.value.toLowerCase()); filterPlanning()}}>
            <option selected value="">All </option>
            <option value="1" selected>Automne </option>
            <option value="2" selected>Printemps </option>
          </select>
            </td>
            <td>
            <select value={rechSem}  className='form-control myselect' onChange={(e) => {setRechSem(e.target.value.toLowerCase()); filterPlanning()}}>
            <option value="">All </option>
            <option value="1" selected>SM1 </option>
            <option value="2" selected>SM2 </option>
            <option value="3" selected>SM3 </option>
            <option value="4" selected>SM4 </option>
            <option value="5" selected>SM5 </option>
            <option value="6" selected>SM6 </option>
          </select>
            </td>
            <td>
            <select className='form-control myselect' onChange={(e) => {setRechJour(e.target.value.toLowerCase()); filterPlanning()}}>
            <option value="" selected>All </option>
            {jours.map((j,i) =><option value={j} key={i}>{j}</option>)}
          </select>
            </td>
            <td>
            <select className='form-control myselect' onChange={(e) => {setRechHeure(e.target.value.toLowerCase()); filterPlanning()}}>
            <option value="" selected>All </option>
            {creneaux.map((c,i) =><option value={c} key={i}>{c}</option>)}
          </select>
            
            </td>
            <td>
            <select className='form-control myselect' onChange={(e) => {setRechSalle(e.target.value); filterPlanning()}}>
            <option value="" selected>All </option>
            {salles.map((s,i) =><option value={s.name_fr} key={i}>{s.name_fr}</option>)}
          </select>
            </td>
            <td>

            </td>
            <td>
            <select  className='form-control myselect' onChange={(e) => {setRechHebdo(parseInt(e.target.value)); filterPlanning()}}>
            <option value="-1" selected>All</option>
            <option value="1">Oui </option>
            <option value="0">Non</option>
          </select>
            </td>
            <td></td>
	          <td></td>
            </tr>
              {
              filtredPlannings.map((p,i) =><tr key={i}>
            
            <td>{p.nom_fr} {p.prenom_fr}</td>
            <td>{p.module_name}</td>
            <td>{p.libelle_filiere}</td>
            <td>{p.libelle_departement}</td>
            <td>{p.id_sessions==1?"Automne":"Printemps"}</td>
            <td>{"SM"+p.id_semestre}</td>
            <td>{p.jour}</td>
            <td>{p.creneau}</td>
            <td>{p.salle}</td>
            <td>{p.groupe}</td>
            <td>{p.hebdo==0?"Non":"Oui"}</td>
            <td>{p.note}</td>
	    <td>
        <Button onClick={()=>handleShow(p.id)} variant='text' ><FontAwesomeIcon icon={faTrash} color='red' /></Button>
        <Button onClick={()=>{
          handleShow2(p.id);setModifPlan(prevState =>({
          ...prevState ,
          "id":p.id,
          "nom_fr": p.nom_fr,
          "prenom_fr": p.prenom_fr,
          "module_name": p.module_name,
          "libelle_filiere": p.libelle_filiere,
          "libelle_departement": p.libelle_departement,
          "session": p.id_sessions == 1 ? "Automne" : "Printemps",
          "semestre": "SM" + p.id_semestre,
          "jour": p.jour,
          "creneau": p.creneau,
          "salle": p.salle,
          "groupe": p.groupe,
          "hebdo": p.hebdo == 0 ? "Non" : "Oui",
          "note": p.note,
          "id_module":p.id_module,
          "annee_univ":p.annee_univ,
          "id_professor":p.id_professor
        }))}} variant='text' ><FontAwesomeIcon icon={faEdit} color='green' /></Button>
      </td>
              </tr>)}
            </tbody>
      </Table>
      {/***********Modal*********************/}
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer?</Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
          <Button variant='contained' color='warning' onClick={handleClose}>
            Annuler
          </Button>
          <Button variant='contained' color='info' onClick={()=>{deletePlanning(del);handleClose();}}>
            Oui
          </Button>
          </Stack>
        </Modal.Footer>
      </Modal>

    {/*************************************/}
    {/***********Modal message addPlanning*********************/}
    <Modal show={show2} onHide={handleClose2}>
        <Modal.Header closeButton>
          <Modal.Title>Planning </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classe}>{ <div dangerouslySetInnerHTML={{ __html: msg }} /> }</Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
         
          <Button variant='contained' color='info' onClick={()=>{handleClose2();}}>
            OK
          </Button>
          </Stack>
        </Modal.Footer>
      </Modal>

    {/*************************************/}
     {/***********Modal Edit*********************/}
<Modal show={showEdit} onHide={handleCloseEdit} className='modal-lg'>
        <Modal.Header closeButton>
          <Modal.Title>Modification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='text-center text-success h4'>Prof: {modifPlan.prenom_fr} {modifPlan.nom_fr}</p>
          <p className='h6'>Filière: {modifPlan.libelle_filiere}</p>
          <p className='h6'>Groupe: {modifPlan.groupe}</p>
          <p className='h6'>Module: {modifPlan.module_name}</p>
          <p className='h6'>Semestre: {modifPlan.semestre} - Session: {modifPlan.session}</p>
          <hr className='text-danger'/>
          <p className='text-danger h5'>Planning: {modifPlan.jour}  {modifPlan.creneau}  {modifPlan.salle} </p>
          <hr className='text-danger'/>
          
          <Form onSubmit={handleSubmit(sendUpdatePlanning)}>
          <Row>
    {/*====================================Jour==================================== */}
    <input type='hidden' name='id' value={modifPlan.id} {...register('idUpdate')} />
    <input type='hidden' name='anneeuniv' value={modifPlan.annee_univ} {...register('annee_univ')} />
    <input type='hidden' name='idModule' value={modifPlan.id_module} {...register('idModule')} />
    <input type='hidden' name='idProf' value={modifPlan.id_professor} {...register('idProf')} />
    
    <Form.Group className="mb-3 col-3" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Jour</Form.Label>
            <Form.Select {...register('jour')} className='myselect' >
                  <option value=''>Jour</option>
                  {jours.map((j,i) =><option value={j} key={i}>{j}</option>)}
                  
              </Form.Select>
        </Form.Group>
        {/*====================================Créneau==================================== */}
        <Form.Group className="mb-3 col-5" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Créneau</Form.Label>
            <Form.Select {...register('creneau')} className='myselect'>
            <option value=''>Créneau</option>
            {creneaux.map((c,i) =><option value={c} key={i}>{c}</option>)}
             </Form.Select>
        </Form.Group>
        {/*====================================salle==================================== */}
        <Form.Group className="mb-3 col-4" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Salle</Form.Label>
            <Form.Select {...register('salle')} className='myselect' >
                  <option value=''>Choisir la salle</option>
                  {salles.map((s,i) =><option value={s.name_fr} key={i}>{s.name_fr}</option>)}
                  
              </Form.Select>
        </Form.Group>
     
</Row>
<Row>
    {/*====================================Hébdo==================================== */}
    <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Hébdomadaire (Par défaut)</Form.Label>
            <Form.Check {...register('hebdo')} // prettier-ignore
                  type="switch"
                  id="custom-switch"
                  label="Irrégulier"
      />
        </Form.Group>
     {/*====================================Créneau==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='departement_label'>Note</Form.Label>
            <Form.Control className='textareaplanning' {...register('note')} as="textarea" rows={3} />
        </Form.Group>
</Row>
{/*====================================Button affecter==================================== */}
<Row>
<Form.Group className="mb-3 col-4">
  <Button className="col-12" variant="contained" color="success" type="submit">Modifier</Button>
</Form.Group>

  <Form.Group className=" col-4 mb-3">
  <Button onClick={()=>reset()} className='col-4' variant="contained" color="warning" type="reset">Effacer</Button>
  </Form.Group>
  </Row>
          </Form>

        </Modal.Body>
        
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
          <Button variant='contained' color='warning' onClick={handleCloseEdit}>
            Annuler
          </Button>
          </Stack>
        </Modal.Footer>
      </Modal>

    {/*************************************/}
      </Col>
     </Row>
    </Container>
  )
}

export default AddPlanning