import React, { useEffect,useCallback, useState } from 'react'

import { BarChart } from '@mui/x-charts/BarChart';
import { Box, Grid, Paper, Typography } from '@mui/material';
import Nav from '../Nav';
import { styled } from '@mui/material/styles';
import { PieChart, axisClasses } from '@mui/x-charts';
import api from '../../../../config/axios';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
const StatProf = () => {
  //-----------------------------
  const [year,setYear] = useState(0);
  const [perData2,setPerData] = useState([0,0,0]);
  const [stagData2,setStagData] = useState([0,0,0]);
  const [vacData2,setVacData] = useState([0,0,0]);
  //-----------------------------------------------
  const getYearUniv =useCallback(async () =>{
    console.log("im getYearUniv im executing.....");
    const url = "/api/anneeuniv";
    api.get(url)
    .then(rep =>{
      
        setYear(rep.data.annee[0].annee_univ);
       
        
    }).catch(err => {
      console.error(err);
    });
  },[]);
  //-----------------------------------------------------
  const typeOfProf = (req,rep)=>{
    api.get('/api/statistic/typeofprof')
    .then(
      (rep)=>{
      console.log("-----statistic typeofprof---");
      let automne = [0,0,0];
      let printemps = [0,0,0];
      if(rep.data.sess1.length>0){
        for(let i=0; i<rep.data.sess1.length; i++){
          if(rep.data.sess1[i].category==="Permanent"){
            automne[0] =rep.data.sess1[i].nombre_de_p;
          }else if(rep.data.sess1[i].category==="Stagiaire"){
            automne[1] =rep.data.sess1[i].nombre_de_p;
          }else if(rep.data.sess1[i].category==="Vacataire"){
            automne[2] =rep.data.sess1[i].nombre_de_p;
          } 
        }
      }

      if(rep.data.sess2.length>0){
        for(let i=0; i<rep.data.sess2.length; i++){
          if(rep.data.sess2[i].category==="Permanent"){
            printemps[0] =rep.data.sess2[i].nombre_de_p;
          }else if(rep.data.sess2[i].category==="Stagiaire"){
            printemps[1] =rep.data.sess2[i].nombre_de_p;
          }else if(rep.data.sess2[i].category==="Vacataire"){
            printemps[2] =rep.data.sess2[i].nombre_de_p;
          } 
        }
      }
      console.log(automne);
      console.log(printemps);
      
    setPerData([automne[0],printemps[0]]);
    setStagData([automne[1],printemps[1]]);
    setVacData([automne[2],printemps[2]]);  
    })
    .catch((error)=>console.error(error));
  }
  useEffect(()=>{
    getYearUniv();
    typeOfProf();
  },[getYearUniv]);
  //----------------------------
//+++++++++++++++++++++++++++

const data = [
  { id: 0, value: perData2[0], label: 'Permanents' },
  { id: 1, value: stagData2[0], label: 'Stagiaires' },
  { id: 2, value: vacData2[0], label: 'Vacataires' },
];


//+++++++++++++++++

const xLabels = [
  'Automne',
  'Printemps', 
];
const chartSetting = {
  
  yAxis: [
    {
      label: 'Nombre d\'enseignants',
    },
  ],
  width: 500,
  height: 300,
  sx: {
    [`.${axisClasses.left} .${axisClasses.label}`]: {
      transform: 'translate(-10px, 0)',
    },
  },
};
  return (
    <Box sx={{ width: '100%' }}>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} sm={12}>
        <Nav/>
      </Grid>
      <Grid item sm={12} md={6}>
        <Item>
        <Typography className='text-center'> Enseignants intervenant pour {year}/{year+1} </Typography>
      {/*==================Graphique1==================== */}
      <BarChart
      series={[
        { data: perData2, label: 'Permanents', id: 'pvId' },
        { data: stagData2, label: 'Stagiaires', id: 'uvId' },
        { data: vacData2, label: 'Vacataires', id: 'vacId' },
      ]}
      xAxis={[{ data: xLabels, scaleType: 'band' }]}
      {...chartSetting}
    />
      {/*====================================== */}
      </Item>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Item>
        <Typography className='text-center'> Enseignants intervenant dans la session d'automne {year}/{year+1}</Typography>
      {/*==================Graphique2 pie==================== */}
      <PieChart
      series={[
        {
          data,
          highlightScope: { faded: 'global', highlighted: 'item' },
          faded: { innerRadius: 60, additionalRadius: -10, color: 'gray' },
        },
      ]}
      height={300}
    />
       
      {/*====================================== */}
      </Item>
      </Grid>
    </Grid>
  </Box>

  )
}

export default StatProf
