import * as React from 'react';
import Button from '@mui/material/Button';
import DeleteIcon from '@mui/icons-material/Delete';
import SendIcon from '@mui/icons-material/Send';
import { Box, Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { GiTeacher } from "react-icons/gi";
import { FcStatistics } from "react-icons/fc";
import { AiOutlineRollback } from "react-icons/ai";

export default function Nav() {
    const navigate = useNavigate();
  return (
    <Box sx={{ width: '100%' }}>
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
             <Grid item xs={6} sm={2}>
                    <Button  onClick={()=>navigate('/statistique')} size='large' variant="contained" startIcon={<FcStatistics />}>
                           Home             
                    </Button>
            </Grid>
            <Grid item xs={6} sm={2}>
                    <Button  onClick={()=>navigate('/statprof')} size='large' variant="contained" startIcon={<GiTeacher />}>
                                        Enseignants
                    </Button>
            </Grid>
            
            <Grid item xs={6} sm={2}>
                <Button onClick={()=>navigate('/cpadmin')} size='large' variant="contained" endIcon={<AiOutlineRollback/>}>
                            Retour
                </Button>
            </Grid>
            
      </Grid>
    </Box>
    
  );
}