import { Button } from 'react-bootstrap';
import Card from 'react-bootstrap/Card';
import { useNavigate } from 'react-router-dom';

function Cardfiliere(props) {
  const navigate = useNavigate();
  return (
    <>
      
        <Card
          className="cardfil mb-2 mt-2 col-md-3"
          text='light'
          style={{'marginRight': '10px','fontFamily':'aljazeera'}}
        >
          <Card.Header className='text-center h4'>{props.type}</Card.Header>
          <Card.Body>
            <Card.Title className='text-center'>{props.titre}</Card.Title>
            <Card.Text>
              <Button onClick={()=>navigate('/listemodules/'+props.idfil+'/'+props.titre)} variant="light">Modules </Button>
            </Card.Text>
          </Card.Body>
        </Card>
      
    </>
  );
}

export default Cardfiliere;