import React from 'react'
import { PDFViewer } from '@react-pdf/renderer';
import PdfPAge from './PdfPAge';

function InvitationRattrapage() {
    
    
    return (
    
        <PDFViewer style={{width:'100%', height:'100vh'}}>
          <PdfPAge nom="اربك تكست هو اول موقع يسمح"/>
        </PDFViewer>
        
     
    );
}

export default InvitationRattrapage