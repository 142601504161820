import React from 'react'

import { Col, Row } from 'react-bootstrap'
import Nav from '../Nav'

const StatAbsence = () => {
  return (
    
    <div>
        <Row>
            <Col>
            <Nav/>
            </Col>
        </Row>

        <Row>
            <Col>
            <h1>Abs</h1>
            </Col>
        </Row>
    </div>

  )
}

export default StatAbsence
