import React, {useState, useEffect, useContext, useCallback} from 'react';
import { AuthenticateContext } from '../../config/AuthContext';
import api from '../../config/axios';
import { useForm } from 'react-hook-form';
import { Form, Container, Row, Col, Table, Modal } from 'react-bootstrap';
import { Button, Stack } from '@mui/material';
import { fa9, faDeleteLeft, faEdit, faFileExcel, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Menu from './Menu';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import { sakkal } from '../../config/arabicfontspdf';
import autoTable from 'jspdf-autotable';

function AddProf() {
    const [authInfo] = useContext(AuthenticateContext);
    const [profs, setProfs] = useState([]);
    const [departements, setDepartements] = useState([]);
    //-------------------filtrage---------------
    const [filtredProfs, setFiltredProfs] = useState([]);
    const [rechNom, setRechNom] = useState("");
    const [rechGrade, setRechGrade] = useState("");
    const [rechCat, setRechCat] = useState("");
    const [rechRoles, setRechRoles] = useState("");
    const [rechDep, setRechDep] = useState("");
    const [nbrProfs, setNbrProfs] = useState(0);
    const [indicateur, setIndicateur] = useState(false);
    //-----------------Modal-------------------
    const [show, setShow] = useState(false);
    const [del, setDel] = useState(0);
    const handleClose = () => setShow(false);
    const handleShow = (delId) => {setDel(delId); setShow(true); }
    //-----------------Modal edit-------------------
    const [showEdit, setShowEdit] = useState(false);
    const [update, setUpdate] = useState(0);
    const handleCloseEdit = () => setShowEdit(false);
    const handleShowEdit = (updateId) => {setUpdate(updateId); setShowEdit(true); }
    const { register, reset,setValue, handleSubmit} = useForm();
    const roles = ["","Enseignant","Chef-département","Vice Doyen","Doyen"]
    //============================AllUsers=======================
    const getAllProfs =useCallback(async () =>{
        const url = "/api/profs";
        api.get(url,{
          headers: {
            'auth-token': authInfo.token
            }
        })
        .then(rep =>{
            console.log(rep.data.profs);
            setProfs(rep.data.profs);
            setIndicateur(true);
        }).catch(err => {
          console.error(err);
        });
   },[]);
   //============================AllDep=======================
   const getAllDepart =useCallback(async () =>{
    const url = "/api/alldepart";
    api.get(url)
    .then(rep =>{
        console.log(rep.data.departements);
        setDepartements(rep.data.departements);
    }).catch(err => {
      console.error(err);
    });
},[]);
//==============nom de département=======
const deptName = (id)=>{
    const foundIndex = departements.findIndex(item => item.id_departement === id);
    return departements[foundIndex]["libelle_departement"];
}
//==============prof from profs list by id=======
let proftoedit = {};
const profEdit = (id)=>{
    const foundIndex = profs.findIndex(item => item.id_professor === id);
    proftoedit = profs[foundIndex];
    setValue('som2',proftoedit.id_professor); // setValue from useFrom 
    setValue('last_som',proftoedit.id_professor);
    setValue('cin2',proftoedit.cin);
    setValue('cat2',proftoedit.category);
    setValue('nom2',proftoedit.nom_fr);
    setValue('prenom2',proftoedit.prenom_fr);
    setValue('nomar2',proftoedit.nom_ar);
    setValue('prenomar2',proftoedit.prenom_ar);
    setValue('grade2',proftoedit.grade);
    setValue('role2',proftoedit.role);
    setValue('id_departement2',proftoedit.id_departement);
        
}

//==========================Delete prof========================
const deleteProf = async (id) => {
    api.delete('/api/deleteprof/' +id,{
      headers: {
        'auth-token': authInfo.token
        }
    }).then(rep => {
      console.log(rep.data);
      getAllProfs();
      
    }).catch(err => {
      console.log(err);
    });
  }
  //=========================addUser==========
  const addNewProf = (data) => {
    console.log(data);
    api.post('/api/prof',{
        
            "id_professor":  data.som,
			"nom_fr": data.nom,
			"prenom_fr": data.prenom,
			"nom_ar": data.nomar,
			"prenom_ar": data.prenomar,
			"cin": data.cin,
			"grade": data.grade,
			"vhl": "",
			"category": data.cat,
			"role": data.role,
			"id_departement": data.id_departement,
    },
    {
      headers: {
        'auth-token': authInfo.token
        }
    }
    ).then(rep =>{
       getAllProfs();
    }).catch(err => {
      console.log(err);
      console.log(authInfo.token)
    });
  }
  //=========================addUser==========
  const updateProf = (data) => {
    console.log(data);
    let url = "/api/editprof/"+data.last_som;
    console.log(url);
    api.put(url,{
        
     "id_professor":  data.som2,
			"nom_fr": data.nom2,
			"prenom_fr": data.prenom2,
			"nom_ar": data.nomar2,
			"prenom_ar": data.prenomar2,
			"cin": data.cin2,
			"grade": data.grade2,
			"vhl": "",
			"category": data.cat2,
			"role": data.role2,
			"id_departement": data.id_departement2,
    },
    {
      headers: {
        'auth-token': authInfo.token
        }
    }
    ).then(rep =>{
       getAllProfs();
    }).catch(err => {
      console.log(err);
      console.log(authInfo.token)
    });
  }
  //=============================filtrage==================
  const filtredListProf = useCallback(() => {
    let lst = profs?.filter(p => {
      return rechNom === '' ? p : p.nom_fr.toLowerCase().includes(rechNom);
    }).filter(p => {
      return rechGrade === '' ? p : p.grade === rechGrade;
    }).filter(p => {
      return rechCat === '' ? p : p.category.toLowerCase().includes(rechCat);
    }).filter(p => {
      return rechRoles == '' ? p : p.role == rechRoles;
    }).filter(p => {
      return rechDep === '' ? p : p.id_departement==rechDep;
    });
    setFiltredProfs(lst);
    setNbrProfs(lst.length);
    setIndicateur(false);
  },[rechCat,rechRoles,rechDep,rechNom,rechGrade,nbrProfs,indicateur])
  //==============================export Excel========================
  const dataForExport = useCallback(()=>{
    let lst = profs?.filter(p => {
      return rechNom === '' ? p : p.nom_fr.toLowerCase().includes(rechNom);
    }).filter(p => {
      return rechGrade === '' ? p : p.grade === rechGrade;
    }).filter(p => {
      return rechCat === '' ? p : p.category.toLowerCase().includes(rechCat);
    }).filter(p => {
      return rechRoles === '' ? p : p.role==rechRoles;
    }).filter(p => {
      return rechDep === '' ? p : p.id_departement==rechDep;
    });
   lst.map(item => item.role = roles[item.role]);
   lst.map(item => item.departement=deptName(item.id_departement));
    var wb = XLSX.utils.book_new(),
     ws = XLSX.utils.json_to_sheet(lst);
    XLSX.utils.book_append_sheet(wb, ws,"profs");
    XLSX.writeFile(wb, "Enseignants.xlsx");
    
   },[rechCat,rechRoles,rechDep,rechNom,rechGrade,indicateur])

   
//===============================================================
  useEffect(()=>{
    getAllDepart();
   },[getAllDepart]);
   //===============================================================
   useEffect(()=>{
    getAllProfs();
   },[getAllProfs]);
   //===============================================================
   useEffect(()=>{
    filtredListProf();
   },[filtredListProf]);
 //===============================================================
  return (
    <Container>
    <Menu/>
    <Row>
        <Col>
         <h3 className='titrefil text-center mt-4'>Ajouter un(e) enseignant(e)</h3>
        </Col>
    </Row>

    <Row>
    <Col>
        <Form onSubmit={handleSubmit(addNewProf)}>
<Row>
  {/*====================================Som ou id==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Numéro de somme ou CIN</Form.Label>
            <Form.Control className='modif' type="text" {...register('som')} />
        </Form.Group>
{/*====================================Cat==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Catégorie</Form.Label>
            <Form.Select  className='myselect'  {...register('cat')} >
                  <option value='Permanent'>Permanent</option>
                  <option value='Stagiaire'>Stagiaire</option>
                  <option value='Vacataire'>Vacataire</option>
                  <option value='Contractuel '>Contractuel</option>
                  
              </Form.Select>
        </Form.Group>

</Row>
<Row>
  {/*====================================Nom==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Nom</Form.Label>
            <Form.Control  className='modif' type="text" {...register('nom')} />
        </Form.Group>
{/*====================================prénom==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Prénom</Form.Label>
            <Form.Control  className='modif' type="text" {...register('prenom')} />
        </Form.Group>

</Row>
<Row>
  {/*====================================NomAr==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Nom (Ar)</Form.Label>
            <Form.Control  className='modif' type="text" {...register('nomar')} />
        </Form.Group>
{/*====================================prénom==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Prénom (Ar)</Form.Label>
            <Form.Control  className='modif' type="text" {...register('prenomar')} />
        </Form.Group>

</Row>
<Row>
  {/*====================================CIN==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>CIN</Form.Label>
            <Form.Control  className='modif' type="text" {...register('cin')} />
        </Form.Group>
{/*====================================Grade==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Grade</Form.Label>
            <Form.Select  className='myselect' {...register('grade')} >
                    <option value="PES">Professeur de l'Enseignement Supérieur</option>
                    <option value='MCH'>Maître de conférences Habilité</option>
                    <option value='MC'>Maître de conférences</option>
                    <option value='Docteur'>Docteur</option>
                    <option value='Ingénieur'>Ingénieur</option>
                    <option value='Administrateur'>Administrateur</option>  
                    <option value='Autre'>Autre</option>      
              </Form.Select>
        </Form.Group>

</Row>

<Row>
    {/*====================================Role==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Role</Form.Label>
            <Form.Select  className='myselect'  {...register('role')} >
                  <option value='1'>Enseignant</option>
                  <option value='2'>Chef-département</option>
                  <option value='3'>Vice Doyen</option>
                  <option value='4'>Doyen</option>
                  
              </Form.Select>
        </Form.Group>
     {/*====================================Departement==================================== */}
     <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Département</Form.Label>
            <Form.Select  className='myselect'  {...register('id_departement')} >
                  <option value=''></option>
                  {departements.map(dep =><option key={dep.id_departement} value={dep.id_departement}>{dep.libelle_departement}</option>)}
                  
              </Form.Select>
        </Form.Group>
</Row>
{/*====================================Button Ajouter==================================== */}
<Form.Group className="mb-3">
  <Button className='col-12' variant="contained" color="info" type="submit">
                    Ajouter
                  </Button>
          </Form.Group>
        </Form>
        </Col>
    </Row>
{/**=========================================================== */}

<Row>
     <Col>
      <hr className='trait'/>
      <h3>Filtres</h3>
      </Col>
</Row>
<Row>
<Col>
      <table>
        <tr>
          <td>
          <div class="form-group">
           <label className='update_label'>Nom</label>
          <input type='text' className='form-control filtreplanning' onChange={e =>{setRechNom(e.target.value.toLowerCase()); filtredListProf();}} />
          </div>
          </td>
          <td>
          <div class="form-group">
           <label className='update_label'>Grade</label>
          <select  className='form-control myselect' onChange={e =>{setRechGrade(e.target.value); filtredListProf();}}>
                  <option selected value=''>All</option>
                  <option value="PES">Professeur de l'Enseignement Supérieur</option>
                    <option value='MCH'>Maître de conférences Habilité</option>
                    <option value="MC">Maître de conférences</option>
                    <option value='Docteur'>Docteur</option>
                    <option value='Ingénieur'>Ingénieur</option>
                    <option value='Administrateur'>Administrateur</option>  
                    <option value='Autre'>Autre</option>
          </select>
          </div>
          </td>
          <td>
          <div class="form-group">
           <label className='update_label'>Catégorie</label>
          <select  className='form-control myselect' onChange={e =>{setRechCat(e.target.value.toLowerCase()); filtredListProf();}}>
          <option value='Permanent'>Permanent</option>
                  <option selected value=''>All</option>
                  <option value='Stagiaire'>Stagiaire</option>
                  <option value='Vacataire'>Vacataire</option>
                  <option value='Contractuel '>Contractuel</option>
          </select>
          </div>
          </td>
          <td>
          <div class="form-group">
           <label className='update_label'>Role</label>
          <select  className='form-control myselect' onChange={e =>{setRechRoles(e.target.value); filtredListProf();}}>
          <option selected value=''>All</option>
          <option value='1'>Enseignant</option>
                  <option value='2'>Chef-département</option>
                  <option value='3'>Vice Doyen</option>
                  <option value='4'>Doyen</option>
          </select>
          </div>
          </td>
          <td>
          <div class="form-group">
           <label className='update_label'>Département</label>
          <select  className='form-control myselect' onChange={e =>{setRechDep(e.target.value); filtredListProf();}}>
          <option selected value=''>All</option>
          {departements.map(dep =><option key={dep.id_departement} value={dep.id_departement}>{dep.libelle_departement}</option>)}
          </select>
          </div>
          </td>
          
        </tr>
      </table>
      </Col>
</Row>
<Row>
   <Col>
        <Button variant="contained" color="success" className='col-3 mb-2' onClick={()=>dataForExport()} ><FontAwesomeIcon size='2x' icon={faFileExcel} /></Button>
        
  </Col>
</Row>
<Row>
     <Col>
      <hr className='trait'/>
      <h3>{nbrProfs} Enseignants</h3>
      </Col>
</Row>
    <Table striped bordered hover className='tab'>
   <thead >
    <tr>
      <th>ID</th>
      <th>Nom</th>
      <th>Prénom</th>
      <th>Nom (Ar)</th>
      <th>Prénom (Ar)</th>
      <th>CIN</th>
      <th>Grade</th>
      <th>Catégorie</th>
      <th>Role</th>
      <th>Département</th>
      <th><FontAwesomeIcon size={fa9} icon={faDeleteLeft} /></th>
      <th><FontAwesomeIcon size={fa9} icon={faEdit} /></th>
    </tr>
  </thead>
      <tbody>
        {filtredProfs.map(prof =><tr key={prof.id_professor}><td>{prof.id_professor}</td><td>{prof.nom_fr}</td><td>{prof.prenom_fr}</td><td>{prof.nom_ar}</td><td>{prof.prenom_ar}</td><td>{prof.cin}</td><td>{prof.grade}</td><td>{prof.category}</td><td>{roles[prof.role]}</td><td>{deptName(prof.id_departement)}</td><td><Button onClick={()=>handleShow(prof.id_professor)} variant='text' ><FontAwesomeIcon icon={faTrash} color='red' /></Button></td><td><Button onClick={()=>{profEdit(prof.id_professor); console.log(proftoedit.nom_ar);handleShowEdit(prof.id_professor);}} variant='text' ><FontAwesomeIcon icon={faEdit} color='green' /></Button></td></tr>)}

      </tbody>
</Table>
{/***********Modal*********************/}
<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>Voulez-vous vraiment supprimer?</Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
          <Button variant='contained' color='warning' onClick={handleClose}>
            Annuler
          </Button>
          <Button variant='contained' color='info' onClick={()=>{deleteProf(del);handleClose();}}>
            Oui
          </Button>
          </Stack>
        </Modal.Footer>
      </Modal>

    {/*************************************/}
{/***********Modal edit*********************/}
<Modal show={showEdit} onHide={handleCloseEdit}>
        <Modal.Header closeButton>
          <Modal.Title>Modification</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         {/* ===========================Form Edit =========================== */} 
          <Row>
    <Col>
        <Form onSubmit={handleSubmit(updateProf)}>
<Row>
   {/*====================================Som ou id ancien==================================== */}

      <Form.Control type="hidden" {...register('last_som')} />
  {/*====================================Som ou id==================================== */}

        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Numéro de somme ou CIN</Form.Label>
            <Form.Control  className='modif' type="text" {...register('som2')} />
        </Form.Group>
{/*====================================Cat==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Catégorie</Form.Label>
            <Form.Select  className='myselect'  {...register('cat2')} >
                  <option value='Permanent'>Permanent</option>
                  <option value='Stagiaire'>Stagiaire</option>
                  <option value='Vacataire'>Vacataire</option>
                  <option value='Contractuel '>Contractuel</option>
                  
              </Form.Select>
        </Form.Group>

</Row>
<Row>
  {/*====================================Nom==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Nom</Form.Label>
            <Form.Control  className='modif' type="text" {...register('nom2')} />
        </Form.Group>
{/*====================================prénom==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Prénom</Form.Label>
            <Form.Control  className='modif' type="text" {...register('prenom2')} />
        </Form.Group>

</Row>
<Row>
  {/*====================================NomAr==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Nom (Ar)</Form.Label>
            <Form.Control  className='modif' type="text" {...register('nomar2')} />
        </Form.Group>
{/*====================================prénom==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Prénom (Ar)</Form.Label>
            <Form.Control  className='modif' type="text" {...register('prenomar2')} />
        </Form.Group>

</Row>
<Row>
  {/*====================================CIN==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>CIN</Form.Label>
            <Form.Control  className='modif' type="text" {...register('cin2')} />
        </Form.Group>
{/*====================================Grade==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Grade</Form.Label>
            <Form.Select  className='myselect'  {...register('grade2')} >
                    <option value="PES">Professeur de l'Enseignement Supérieur</option>
                    <option value='MCH'>Maître de conférences Habilité</option>
                    <option value='MC'>Maître de conférences</option>
                    <option value='Docteur'>Docteur</option>
                    <option value='Ingénieur'>Ingénieur</option>
                    <option value='Administrateur'>Administrateur</option>  
                    <option value='Autre'>Autre</option>      
              </Form.Select>
        </Form.Group>

</Row>

<Row>
    {/*====================================Role==================================== */}
        <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Role</Form.Label>
            <Form.Select  className='myselect'  {...register('role2')} >
                  <option value='1'>Enseignant</option>
                  <option value='2'>Chef-département</option>
                  <option value='3'>Vice Doyen</option>
                  <option value='4'>Doyen</option>
                  
              </Form.Select>
        </Form.Group>
     {/*====================================Departement==================================== */}
     <Form.Group className="mb-3 col-6" controlId="formBasicEmail">
            <Form.Label className='update_label'>Département</Form.Label>
            <Form.Select className='myselect'    {...register('id_departement2')} >
                  <option value=''></option>
                  {departements.map(dep =><option key={dep.id_departement} value={dep.id_departement}>{dep.libelle_departement}</option>)}
                  
              </Form.Select>
        </Form.Group>
</Row>
{/*====================================Button Ajouter==================================== */}
<Form.Group className="mb-3">
  <Button className='col-12' variant="contained" color="success" type="submit" onClick={handleCloseEdit}>
                    Modifier
                  </Button>
          </Form.Group>
        </Form>
        </Col>
    </Row>
        {/* ===========================Fin Form Edit =========================== */} 

        </Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
          <Button variant='contained' color='warning' onClick={handleCloseEdit}>
            Annuler
          </Button>
         
          </Stack>
        </Modal.Footer>
      </Modal>

    {/*************************************/}

    </Container>
  )
}

export default AddProf