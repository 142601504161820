import { Container, Row, Col } from "react-bootstrap";
//import AddPlanning from "./views/AddPlanning";
import Login from "./views/Login";
import { AuthProvider } from "./config/AuthContext";
import { Outlet, Route, Routes } from "react-router-dom";
import AddPlanning from "./views/admin/AddPlanning";
import ProtectedRoutes from "./config/ProtectedRoutes";
import './stl.css';
import CpDepart from "./views/departement/CpDepart";
import CpAdmin from "./views/admin/CpAdmin";
import ModulesListe from "./views/departement/ModulesListe";
import Affecter from "./views/departement/Affecter";
import Users from "./views/admin/Users";
import AddUnivYear from "./views/admin/AddUnivYear";
import ShowPlanning from "./views/admin/ShowPlanning";
import AddProf from "./views/admin/AddProf";
import ShowFilierePlanning from "./views/admin/ShowFilierePlanning";
import AddAbsences from "./views/admin/AddAbsences";
import AffecterModuleAuProf2 from "./views/admin/AffecterModuleAuProf2";
import SallesDispo from "./views/admin/SallesDispo";
import ListeAbsences from "./views/admin/ListeAbsences";
import ProfAbsence from "./views/admin/ProfAbsence";
import StatProf from "./views/admin/stat/pages/StatProf";
import StatSalle from "./views/admin/stat/pages/StatSalle";
import StatAbsence from "./views/admin/stat/pages/StatAbsence";
import StatGeneral from "./views/admin/stat/pages/StatGeneral";
import UpdateTitreModule from "./views/admin/UpdateTitreModule";
import ListeVacataires from "./views/admin/ListeVacataires";
import AddRattrapage from "./views/admin/AddRattrapage";
import InvitationRattrapage from "./views/admin/InvitationRattrapage";
import Telecharger from "./views/admin/Telecharger";


function App() {
  
  return (
    <AuthProvider>
   <Container className="container-fluid">
   
    <Row>
    <Outlet/>
    </Row>
    {/***************************Routes********************* */}
    <Routes>
      <Route path="/" element={<Login/>} />
      
    {/*--------------------Protected routes----------------- */}  
      <Route element={<ProtectedRoutes/>}>
          <Route path="/addplanning" element={<AddPlanning/>} />
          <Route path="/showplanning" element={<ShowPlanning/>} />
          <Route path="/sallesdispo" element={<SallesDispo/>} />
          <Route path="/vacataires" element={<ListeVacataires/>} />
          <Route path="/showfiliereplanning" element={<ShowFilierePlanning/>} />
          <Route path="/cpdepartement" element={<CpDepart/>} />
          <Route path="/cpadmin" element={<CpAdmin/>} />
          <Route path="/listemodules/:idfil/:filiere" element={<ModulesListe/>} />
          <Route path="/affecter" element={<Affecter/>} />
          <Route path="/affectermodule2" element={<AffecterModuleAuProf2/>} />
          <Route path="/users" element={<Users/>} />
          <Route path="/addyear" element={<AddUnivYear/>} />
          <Route path="/profs" element={<AddProf/>} />
          <Route path="/addabsences" element={<AddAbsences/>} />
          <Route path="/listeabsences" element={<ListeAbsences/>} />
          <Route path="/profabsence" element={<ProfAbsence/>} />  
          <Route path="/statistique" element={<StatGeneral/>} />
          <Route path="/statprof" element={<StatProf/>} />
          <Route path="/statsalle" element={<StatSalle/>} />
          <Route path="/statabsence" element={<StatAbsence/>} />
          <Route path="/updatetitremodule" element={<UpdateTitreModule/>} />
          <Route path="/addrattrapage" element={<AddRattrapage/>} />
          <Route path="/conv" element={<InvitationRattrapage/>} />
          <Route path="/printest" element={<Telecharger/>} />
      </Route>
    {/*--------------------fin Protected routes------------- */} 
    </Routes>

    
 
  
    
          
     {/***************************Routes********************* */}
   </Container>
   </AuthProvider>
);
}

export default App;
