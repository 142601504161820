import React, { useEffect, useContext, useState } from 'react'
import { signal } from '@preact/signals'
import { useSignals } from "@preact/signals-react/runtime";
import api from '../../config/axios';
import { AuthenticateContext } from '../../config/AuthContext';
import Menu from './Menu';
import { useForm } from 'react-hook-form';
import { Form, Container, Row, Col, Modal } from 'react-bootstrap';
import { Button, Stack} from '@mui/material';
//import { fa9, faDeleteLeft, faFileExcel, faFilePdf, faTrash } from '@fortawesome/free-solid-svg-icons';
const departementSignal = signal([]);
const filieresSignal = signal([]);
const modulesSignal = signal([]);
const objModuleSignal = signal({
    cod_mod: "",
    id_filiere: 0,
    id_module: 0,
    id_semestre: 0,
    id_sessions: 0,
    module_name: "",
    parcours: "",
    vh: 0 
});
function UpdateTitreModule() {
    const [authInfo,setAuthInfo] = useContext(AuthenticateContext);
    const [msg, setMsg] = useState("");
    const [classe, setClasse] = useState("text-info h4");
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => {setShow(true); }
//============================AllDep=======================
const getAllDepart =async () =>{
    const url = "/api/alldepart";
    api.get(url)
    .then(rep =>{
        departementSignal.value = rep.data.departements
        //console.log(departementSignal.value);
    }).catch(err => {
      console.error(err);
    });
}
//-----------------getFiliereOfDepartement----------------
const getFiliereOfDepartement  = async (id_departement) =>{
    const url = "/api/filieresofdepart/"+id_departement;
        api.get(url)
        .then(rep =>{
            filieresSignal.value = rep.data.filieres;    
            //console.log(filieresSignal.value);
            
        }).catch(err => {
           console.error(err);
        });
}
//-----------------getModules----------------
const getModulesOfFilieres  = async (idfil) =>{
     const url = "/api/modulesoffiliere/"+idfil;
    api.get(url)
        api.get(url)
        .then(rep =>{
            console.log(rep.data)
            modulesSignal.value = rep.data.modules;   
        }).catch(err => {
           console.error(err);
        });
}
//-------------------------selected module-----------

const selectedModule = (idmod)=>{
    let myModule = modulesSignal.value.find(cours => cours.id_module == idmod);
    objModuleSignal.value = myModule; 
    
    //console.log(objModuleSignal.value)
}
//---------------------------------------------------
useEffect(()=>{
    getAllDepart();
},[]);
//---------------------------
const { register,reset, handleSubmit} = useForm();
const updateModuleName = (data)=>{
    console.log(data.nom_module);
    api.post('/api/updatemodule',{
        "idModule":objModuleSignal.value.id_module,
        "nom_module":data.nom_module
      },
      {
        headers: {
          'auth-token': authInfo.token
          }
      }).then(rep => {
        console.log(rep.data);
        if(rep.data.success){
        setMsg("<b>Titre bien modifié</b>");
        setClasse("text-info");
        }else{
            setMsg("<b>Titre non modifié!!!!</b>");
                setClasse("text-danger");
        }
    
        setShow(true);
      }).catch(
        err => {
            setMsg("<b>Titre non modifié!!!!</b>");
            setClasse("text-danger");
            setShow(true);
        }
      );
}


useSignals();
  return (
    <Container className="departement_bg">
        <Menu/>
        <h2 className='titrefil text-center mt-4'>Modification du nom de module</h2>
    <Row>
    <Col md={12}>
        <Form>
        {/*====================================Département==================================== */}
    <Form.Group as={Row} className="mb-3" controlId="formBasicEmail">
            
            <Col sm="4">
            <Form.Select className='departement_form' onChange={(e)=>getFiliereOfDepartement(e.target.value)}  aria-label="Default select example">
                <option selected value="">Choisir la département</option>
                {departementSignal.value.map((dep,index) => <option value={dep.id_departement} key={index}>{dep.libelle_departement}</option>)}
            </Form.Select>
            </Col>
            {/*====================================Filière==================================== */}
       
            <Col sm="4">
            <Form.Select className='departement_form' onChange={(e)=>{getModulesOfFilieres(e.target.value);}}  aria-label="Default select example">
                <option selected value="0">Choisir la filière</option>
                {filieresSignal.value.map((fil,i) => <option value={fil.id_filiere} key={i}>{fil.libelle_filiere}</option>)}
            </Form.Select>
            </Col>
            {/*====================================Modules==================================== */}
       
            <Col sm="4">
            <Form.Select className='departement_form' onChange={(e)=>{selectedModule(e.target.value)}}  aria-label="Default select example">
                <option selected value="0">Choisir Module</option>
                {modulesSignal.value.map((mod,i) => <option value={mod.id_module} key={i}>{mod.module_name} - SM{mod.id_semestre}</option>)}
            </Form.Select>
            </Col>
            
    </Form.Group>  
        </Form>
</Col>
</Row>

<Row>
    <Col>
    <h4 className='text-danger'>Nom actuel du module : {objModuleSignal.value.module_name}</h4>
    <h4>Code module: {objModuleSignal.value.cod_mod}</h4>
    </Col>

    <Col md={12}>
        <div>
      <Form onSubmit={handleSubmit(updateModuleName)}>
      <Form.Group className="mb-3 col-12" controlId="formBasicEmail">
            <Form.Label className='h3 text-primary'>Nouveau nom</Form.Label>
            <Form.Control className='h3' size="lg" type="text" {...register('nom_module')} />
        </Form.Group>

        {/*====================================Button affecter==================================== */}
<Row>
<Form.Group className="mb-3 col-4">
  <Button className="col-12" variant="contained" color="info" type="submit">Modifier</Button>
</Form.Group>

  <Form.Group className=" col-4 mb-3">
  <Button onClick={()=>reset()} className='col-4' variant="contained" color="warning" type="reset">Effacer</Button>
  </Form.Group>
  </Row>
      </Form>
      </div>
    </Col>


</Row>
     {/***********Modal message Module update*********************/}
    <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modification du nom de module </Modal.Title>
        </Modal.Header>
        <Modal.Body className={classe}>{ <div dangerouslySetInnerHTML={{ __html: msg }} /> }</Modal.Body>
        <Modal.Footer>
          <Stack spacing={2} direction='row'>
         
          <Button variant='contained' color='info' onClick={()=>{handleClose();}}>
            OK
          </Button>
          </Stack>
        </Modal.Footer>
      </Modal>

    {/*************************************/}   
   </Container>
    
  )
}

export default UpdateTitreModule